/*
 * Created by Kijana J. James on 11/22/23, 12:13 PM.
 * Keejware LLC, All Rights Reserved
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtils } from '../utils/form-utils';
import {
  getCountryCodeControl,
  getPhoneControl,
  PhoneNumberComponent,
} from '../phone-number/phone-number.component';
import { Mfa } from '../../projects/business/src/app/business.models';
import { SnackBarAlertService } from '../snack-bar-alert/snack-bar-alert.service';
import { WindowService } from '../services/window.service';

@Component({
  selector: 'app-mfa',
  standalone: true,
  imports: [CommonModule, PhoneNumberComponent, ReactiveFormsModule],
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.sass'],
})
export class MfaComponent implements OnChanges, OnDestroy {
  @Input() started = false;
  @Input() submitting = false;
  @Input() startText = 'login';
  @Input() verifyText = 'verify';

  @Input() countryCode?: string;
  @Input() phone?: string;
  @Input() serverError?: HttpErrorResponse;
  @Input() reset?: Subject<void>;

  @Output() start = new EventEmitter<string>();
  @Output() verify = new EventEmitter<Mfa>();
  @Output() resendVerification = new EventEmitter<void>();

  canResend = false;
  phoneNumber = '';

  phoneForm = this.fb.nonNullable.group({
    countryCode: getCountryCodeControl(),
    phone: getPhoneControl(),
  });

  verificationCode = new FormControl<number | undefined>(undefined, [
    Validators.required,
  ]);

  formUtils = FormUtils;
  onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private snackBarAlertService: SnackBarAlertService,
    private windowService: WindowService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.handleServerError(changes['serverError']?.currentValue);

    if (changes['reset']?.currentValue) {
      this.reset?.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.phoneForm.reset({
          phone: undefined,
        });
        this.verificationCode.reset(undefined);
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  handleServerError(serverError: HttpErrorResponse | undefined): void {
    if (!serverError) {
      return;
    }

    if (serverError.error.errors?.['verificationCode'] === 'expired') {
      this.verificationCode.reset();
      this.onResendVerification();

      return this.snackBarAlertService.showAlert({
        message: 'this code has expired... another one has been sent!',
      });
    }

    this.formUtils.trySetFormGroupServerErrors(
      this.phoneForm,
      serverError,
      () => {
        this.snackBarAlertService.showAlert({
          message: 'oops! something unexpected went wrong. please try again.',
        });
      }
    );

    this.formUtils.trySetFormControlServerError(
      'verificationCode',
      this.verificationCode,
      serverError
    );
  }

  getLastFourDigitsOfPhone(): string {
    return this.phoneNumber.substring(this.phoneNumber.length - 4);
  }

  onStart(): void {
    if (this.phoneForm.invalid) {
      return this.phoneForm.markAllAsTouched();
    }

    this.phoneNumber = `${this.phoneForm.value.countryCode}${this.phoneForm.value.phone}`;

    this.start.emit(this.phoneNumber);

    this.toggleResendButton();
  }

  onVerify(): void {
    if (this.verificationCode.invalid) {
      return this.verificationCode.markAsTouched();
    }

    this.verify.emit({
      phone: this.phoneNumber,
      verificationCode: +(this.verificationCode.value as number),
    });
  }

  onResendVerification(): void {
    this.resendVerification.emit();
    this.toggleResendButton();
  }

  toggleResendButton(): void {
    const oneMinute = 60 * 1000;

    const enableTimeout = this.windowService.setTimeout(() => {
      this.canResend = true;
      this.windowService.clearTimeout(enableTimeout);
    }, oneMinute);

    this.canResend = false;
  }
}
