<!--
  ~ Created by Kijana J. James on 3/7/23, 3:27 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<footer
  class="footer-container navbar fixed-bottom navbar-expand-sm navbar-dark bg-dark"
>
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      zlaed
      <i class="bi bi-c-circle"></i>
      {{ year }}
    </a>
    <ul class="nav">
      <li class="nav-item">
        <a
          class="nav-link"
          aria-label="terms of service"
          routerLink="/terms-of-service"
          >terms of service</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" aria-label="contact us" routerLink="/contact"
          >contact</a
        >
      </li>
    </ul>
  </div>
</footer>
