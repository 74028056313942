<!--
  ~ Created by Kijana J. James on 11/4/22, 8:38 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<nav
  class="navbar navbar-expand-lg bg-light"
  [ngClass]="{ 'fixed-top': fixedTop }"
>
  <div class="container-fluid">
    <a
      *ngIf="leftNavButtonLink; else brand"
      class="navbar-brand"
      [routerLink]="leftNavButtonLink"
    >
      <i class="bi {{ leftNavButtonIcon || ' bi-chevron-left' }} "></i>
    </a>
    <ng-template #brand>
      <a class="navbar-brand" [routerLink]="brandLink">zlaed</a>
    </ng-template>
    <span class="navbar-text">{{ title }}</span>
    <span class="d-none d-xxl-inline"></span>
    <div class="d-inline-block d-lg-none">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navMenu"
        aria-controls="navMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (blur)="toggleMenu($event)"
        *ngIf="menuLinks?.length"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div
      id="navMenu"
      class="collapse navbar-collapse flex-grow-0 text-end"
      *ngIf="menuLinks?.length"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        @for (menuLink of menuLinks; track menuLink.link) {
          <li class="nav-item">
            <a
              class="nav-link d-inline-block"
              aria-current="page"
              [ngClass]="{ 'border-top': showDivider(menuLink) }"
              [routerLink]="menuLink.link"
              [routerLinkActive]="'active-link'"
              >{{ menuLink.text }}</a
            >
          </li>
        }
      </ul>
    </div>
  </div>
</nav>
<ng-content></ng-content>
