<!--
  ~ Created by Kijana J. James on 11/22/23, 12:13 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<div class="container mfa-container mt-3 mb-3">
  <form
    [formGroup]="phoneForm"
    (ngSubmit)="onStart()"
    *ngIf="!started; else displayVerifyLogin"
  >
    <div class="form-group">
      <div class="row mb-3">
        <div class="col-12">
          <app-phone-number
            [countryCode]="countryCode"
            [countryCodeControl]="phoneForm.controls.countryCode"
            [phone]="phone"
            [phoneControl]="phoneForm.controls.phone"
          ></app-phone-number>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            type="submit"
            [disabled]="submitting"
            class="btn btn-primary w-100"
          >
            {{ startText }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <ng-template #displayVerifyLogin>
    <div class="row">
      <p class="col-12 mb-3 fw-lighter">
        a verification code has been sent to phone XXX-XXX-<span
          class="fw-bolder"
          >{{ getLastFourDigitsOfPhone() }}</span
        >
      </p>
      <div class="col-12 mb-3">
        <div class="form-group">
          <div
            class="form-floating"
            [ngClass]="{
              'is-invalid': formUtils.controlHasError(verificationCode)
            }"
          >
            <input
              type="text"
              [formControl]="verificationCode"
              id="verificationCode"
              class="form-control"
              placeholder="verification code"
              aria-label="verification code"
              maxlength="5"
              [ngClass]="{
                'is-invalid': formUtils.controlHasError(verificationCode)
              }"
            />
            <label for="verificationCode">verification code</label>
          </div>
          <span
            class="invalid-feedback"
            *ngIf="formUtils.controlHasError(verificationCode, 'required')"
          >
            required
          </span>
          <span
            class="invalid-feedback"
            *ngIf="formUtils.controlHasError(verificationCode, 'server')"
          >
            {{ verificationCode.errors?.['server'] }}
          </span>
        </div>
      </div>
      <div class="col-12 mb-3">
        <button
          type="button"
          class="btn btn-success w-100"
          [disabled]="submitting"
          (click)="onVerify()"
        >
          {{ verifyText }}
        </button>
      </div>
      <button
        *ngIf="canResend"
        type="button"
        class="btn btn-link"
        (click)="onResendVerification()"
      >
        didn't receive code? click here to resend
      </button>
    </div>
  </ng-template>
</div>
