/*
 * Created by Kijana J. James on 7/14/23, 11:02 AM.
 * Keejware LLC, All Rights Reserved
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface SnackBarAlert {
  message: string;
  action?: string;
  durationInMilliseconds?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarAlertService {
  alert$ = new Subject<SnackBarAlert>();

  showAlert(alert: SnackBarAlert): void {
    this.alert$.next(alert);
  }
}

export class SnackBarAlertServiceTestStub {
  showAlert(alert: SnackBarAlert): void {
    console.log('showAlert', alert);
  }
}
