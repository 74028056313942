<!--
  ~ Created by Kijana J. James on 8/2/23, 5:41 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<div *ngIf="showCamera; else view" class="d-flex flex-column">
  <app-progress-bar [showProgress]="isCameraLoading"></app-progress-bar>
  <div class="d-flex justify-content-end">
    <i *ngIf="!isCameraLoading" class="bi bi-x fs-1" (click)="onClose()"></i>
  </div>
  <div class="qr-code-scanner-container mt-3 p-1 bg-opacity-10 border rounded">
    <div
      [id]="qrCodeScannerId"
      class="qr-code-scanner"
      [attr.style]="hasCameraLoaded ? 'align-self: center' : undefined"
    ></div>
    <p class="h5 pt-2 text-center">{{ getCameraLoadingMessage() }}</p>
  </div>
</div>
<ng-template #view>
  <ng-content></ng-content>
</ng-template>
