/*
 * Created by Kijana J. James on 1/23/24, 1:23 PM.
 * Keejware LLC, All Rights Reserved
 */

const minute = 60 * 1000;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = month * 12;

export class TimeAgoUtils {
  static getTimeAgo(dateString: string, now = Date.now()): string {
    const timeAgoDate = Date.parse(dateString);
    const dateDifference = now - timeAgoDate;

    if (dateDifference < minute) {
      return 'moments ago';
    }

    if (dateDifference < hour) {
      const mins = Math.floor(dateDifference / minute);
      return `${mins > 1 ? mins + ' mins' : 'a min'} ago`;
    }

    if (dateDifference < day) {
      const hrs = Math.floor(dateDifference / hour);
      return `${hrs > 1 ? hrs + ' hrs' : 'an hr'} ago`;
    }

    if (dateDifference < week) {
      const days = Math.floor(dateDifference / day);
      return `${days > 1 ? days + ' days' : 'a day'} ago`;
    }

    if (dateDifference < month) {
      const weeks = Math.floor(dateDifference / week);
      return `${weeks > 1 ? weeks + ' weeks' : 'a week'} ago`;
    }

    if (dateDifference < year) {
      const mos = Math.floor(dateDifference / month);
      return `${mos > 1 ? mos + ' months' : 'a month'} ago`;
    }

    return dateString;
  }
}
