/*
 * Created by Kijana J. James on 3/10/23, 9:51 PM.
 * Keejware LLC, All Rights Reserved
 */
import { HeaderLink } from './header-link';

export const siteMenuLinks: HeaderLink[] = [
  {
    link: '/biz/login',
    text: 'login',
  },
  {
    link: '/contact',
    text: 'contact',
  },
  {
    link: '/biz/sign-up',
    text: 'sign up',
  },
];
