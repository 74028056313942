/*
 * Created by Kijana J. James on 3/11/23, 2:36 PM.
 * Keejware LLC, All Rights Reserved
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'biz',
    loadChildren: () =>
      import('../../projects/business/src/app/business-routing.module').then(
        (m) => m.BusinessRoutingModule,
      ),
  },
  {
    path: 'shopper',
    loadChildren: () =>
      import('../../projects/shopper/src/app/shopper-routing.module').then(
        (m) => m.ShopperRoutingModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('../../projects/site/src/app/site-routing.module').then(
        (m) => m.SiteRoutingModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
