/*
 * Created by Kijana J. James on 11/28/21, 2:31 PM.
 *  Keejware LLC, All Rights Reserved
 *
 */
export interface HeaderLink {
  text: string;
  link: string;
  showDivider?: boolean;
}
