/*
 * Created by Kijana J. James on 7/21/23, 9:29 AM.
 * Keejware LLC, All Rights Reserved
 */

import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

export class FormUtils {
  static controlHasError(
    formControl: FormControl,
    ...errorKeys: string[]
  ): boolean {
    if (formControl?.touched && Array.isArray(errorKeys)) {
      return errorKeys.some((ek) => formControl.errors?.[ek] !== undefined);
    }

    return false;
  }

  static hasError(
    form: FormGroup,
    formControlName: string,
    errorKey?: string
  ): boolean {
    if (form?.get(formControlName)?.touched) {
      return errorKey !== undefined
        ? form.get(formControlName)?.errors?.[errorKey] !== undefined
        : Boolean(form.get(formControlName)?.errors);
    }

    return false;
  }

  static trySetFormGroupServerErrors(
    formGroup: FormGroup,
    response: HttpErrorResponse,
    fallbackAction?: CallableFunction
  ): void {
    if (response.error?.errors) {
      return Object.keys(response.error.errors).forEach((errorKey) =>
        formGroup.get(errorKey)?.setErrors({
          server: response.error.errors[errorKey],
        })
      );
    }

    if (fallbackAction) {
      fallbackAction();
    }
  }

  static trySetFormControlServerError(
    controlName: string,
    control: FormControl,
    response: HttpErrorResponse
  ): void {
    if (response.error?.errors?.[controlName]) {
      control.setErrors({
        server: response.error?.errors?.[controlName],
      });
    }
  }
}
