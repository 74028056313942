/*
 * Created by Kijana J. James on 8/27/23, 5:54 PM.
 * Keejware LLC, All Rights Reserved
 */

export class ActionUtils {
  constructor(private namespace: string) {}

  type(type: string): string {
    return `${this.namespace} ${type} `;
  }

  static reducerKey(key: string, namespace = 'zlaed::'): string {
    return `${namespace}${key}`;
  }
}
