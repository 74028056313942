/*
 * Created by Kijana J. James on 8/2/23, 5:41 PM.
 * Keejware LLC, All Rights Reserved
 */

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  Html5Qrcode,
  Html5QrcodeResult,
  Html5QrcodeSupportedFormats,
} from 'html5-qrcode';
import { delay, Subject, takeUntil, tap } from 'rxjs';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { SnackBarAlertService } from '../snack-bar-alert/snack-bar-alert.service';

@Component({
  selector: 'app-qr-code-scanner',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, ProgressBarComponent],
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.sass'],
})
export class QrCodeScannerComponent implements OnChanges, OnDestroy {
  @Input() showScanButton = false;
  @Input() qrCodeScannerId = 'qrCodeScanner';
  @Input() startScanning?: Subject<void>;

  @Output() scannedCode = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  onDestroy$ = new Subject<void>();

  showCamera = false;
  isCameraLoading = false;
  hasCameraLoaded = false;
  cameraId!: string;
  qrCodeScanner?: Html5Qrcode;
  wasCodeScanned = false;

  constructor(private snackBarAlertService: SnackBarAlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startScanning'].currentValue) {
      this.startScanning
        ?.pipe(
          takeUntil(this.onDestroy$),
          delay(0),
          tap(() => this.startQrCodeScanner())
        )
        .subscribe();
    }
  }

  startQrCodeScanner(): void {
    this.showCamera = true;
    this.isCameraLoading = true;

    Html5Qrcode?.getCameras()
      .then((devices) => {
        const [camera] = devices;
        this.cameraId = camera.id;

        this.setupCamera();
      })
      .catch(async (error) => {
        console.error('camera error', error);

        this.snackBarAlertService.showAlert({
          message: 'could not load your camera.',
          durationInMilliseconds: 3000,
        });

        await this.onClose();
      });
  }

  setupCamera(): void {
    const config = {
      fps: 10,
      qrbox: { width: 400, height: 400 },
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };

    this.qrCodeScanner = new Html5Qrcode(this.qrCodeScannerId);

    this.qrCodeScanner
      ?.start(
        { facingMode: 'environment' },
        config,
        async (decodedText: string, decodedResult: Html5QrcodeResult) => {
          if (this.wasCodeScanned) {
            return;
          }

          console.log('scanSuccess', decodedText, decodedResult);

          this.wasCodeScanned = true;
          this.showCamera = false;
          this.scannedCode.emit(decodedText);

          await this.closeCamera();
        },
        () => {
          if (this.isCameraLoading) {
            this.isCameraLoading = false;
          }
        }
      )
      .catch((err) => console.log('qrScanner setup error', err));

    this.isCameraLoading = false;
    this.hasCameraLoaded = true;
  }

  getCameraLoadingMessage(): string {
    return this.isCameraLoading
      ? 'loading camera...'
      : 'place camera over qr code for scanning';
  }

  async onClose(): Promise<void> {
    await this.closeCamera();

    this.close.emit();
  }

  async ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();

    await this.closeCamera();
  }

  async closeCamera(): Promise<void> {
    if (this.qrCodeScanner?.isScanning) {
      await this.qrCodeScanner?.stop();
    }

    this.showCamera = false;
  }
}
