/*
 * Created by Kijana J. James on 11/4/22, 8:11 PM.
 * Keejware LLC, All Rights Reserved
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { HeaderLink } from './header-link';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-bar',
  standalone: true,
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.sass'],
  imports: [CommonModule, RouterModule],
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() brandLink = '/';
  @Input() menuLinks?: HeaderLink[];
  @Input() menuIcon?: string;
  @Input() leftNavButtonIcon?: string;
  @Input() leftNavButtonLink?: string | any[];
  @Input() hideBrandLink = false;
  @Input() fixedTop = false;

  currentScreenSize?: string;
  onDestroy$ = new Subject<void>();

  screenSizes = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => this.setCurrentScreenSize(result));
  }

  setCurrentScreenSize(screen: BreakpointState): void {
    for (const query of Object.keys(screen.breakpoints)) {
      if (screen.breakpoints[query]) {
        this.currentScreenSize = this.screenSizes.get(query);
      }
    }
  }

  showDivider(link: HeaderLink): boolean {
    if (!link.showDivider) {
      return false;
    }

    switch (this.currentScreenSize) {
      case 'XSmall':
      case 'Small':
        return true;

      default:
        return false;
    }
  }

  toggleMenu(fe: FocusEvent): void {
    (fe.target as HTMLButtonElement).click();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }
}
