<div class="row">
  <div class="col-5">
    <div class="form-floating">
      <select
        class="form-select"
        id="countryCode"
        aria-label="country code"
        [formControl]="countryCodeControl"
      >
        @for (countryCode of countryCodes; track countryCode.code) {
          <option [value]="countryCode.code">
            {{ countryCode.code }} {{ countryCode.country }}
          </option>
        }
      </select>
      <label for="countryCode">country</label>
    </div>
  </div>
  <div class="col-7">
    <div class="form-group">
      <div
        class="form-floating"
        [ngClass]="{
          'is-invalid': phoneControl.touched && phoneControl.errors
        }"
      >
        <input
          type="tel"
          class="form-control"
          id="phoneNumber"
          placeholder="phone number"
          aria-label="phone number"
          maxlength="10"
          [formControl]="phoneControl"
          [ngClass]="{
            'is-invalid': phoneControl.touched && phoneControl.errors
          }"
        />
        <label for="phoneNumber">phone</label>
      </div>
      <span
        class="invalid-feedback"
        *ngIf="phoneControl.touched && phoneControl.errors?.['required']"
      >
        required
      </span>
      <span
        class="invalid-feedback"
        *ngIf="phoneControl.touched && phoneControl.errors?.['pattern']"
      >
        numbers only
      </span>
      <span
        class="invalid-feedback"
        *ngIf="phoneControl.touched && phoneControl.errors?.[serverError]"
      >
        {{ phoneControl.touched && phoneControl.errors?.[serverError] }}
      </span>
    </div>
  </div>
</div>
