<!--
  ~ Created by Kijana J. James on 12/19/23, 12:10 PM.
  ~ Keejware LLC, All Rights Reserved
  -->
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{ data.msg }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="data?.cancelText">
    {{ data.cancelText }}
  </button>
  <button mat-button (click)="onConfirm()" cdkFocusInitial>
    {{ data.confirmText || 'ok' }}
  </button>
</mat-dialog-actions>
