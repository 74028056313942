<form [formGroup]="addressForm" (ngSubmit)="onSave()" novalidate>
  <div class="container sign-up-container mt-3 mb-3">
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <div
            class="form-floating"
            [ngClass]="{
              'is-invalid': formUtils.hasError(addressForm, 'address1')
            }"
          >
            <input
              type="text"
              id="address1"
              class="form-control"
              placeholder="address"
              aria-label="address"
              formControlName="address1"
              maxlength="128"
              [ngClass]="{
                'is-invalid': formUtils.hasError(addressForm, 'address1')
              }"
            />
            <label for="address1">address</label>
            @if (formUtils.hasError(addressForm, 'address1', 'required')) {
              <span class="invalid-feedback"> required </span>
            }
            @if (formUtils.hasError(addressForm, 'address1', 'server')) {
              <span class="invalid-feedback">
                {{ addressForm.controls.address1.errors?.['server'] }}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <div
            class="form-floating"
            [ngClass]="{
              'is-invalid': formUtils.hasError(addressForm, 'address2')
            }"
          >
            <input
              autocomplete="address-line2"
              type="text"
              id="address2"
              class="form-control"
              placeholder="address 2"
              aria-label="address 2"
              formControlName="address2"
              maxlength="128"
              [ngClass]="{
                'is-invalid': formUtils.hasError(addressForm, 'address2')
              }"
            />
            <label for="address2">address 2</label>
          </div>
          @if (formUtils.hasError(addressForm, 'address2', 'server')) {
            <span class="invalid-feedback">
              {{ addressForm.controls.address2.errors?.['server'] }}
            </span>
          }
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <div
            class="form-floating"
            [ngClass]="{
              'is-invalid': formUtils.hasError(addressForm, 'city')
            }"
          >
            <input
              type="text"
              class="form-control"
              id="city"
              placeholder="city"
              aria-label="city"
              formControlName="city"
              maxlength="64"
              [ngClass]="{
                'is-invalid': formUtils.hasError(addressForm, 'city')
              }"
            />
            <label for="city">city</label>
          </div>
          @if (formUtils.hasError(addressForm, 'city', 'required')) {
            <span class="invalid-feedback"> required </span>
          }
          @if (formUtils.hasError(addressForm, 'city', 'server')) {
            <span class="invalid-feedback">
              {{ addressForm.controls.city.errors?.['server'] }}
            </span>
          }
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        <div class="form-group">
          <div class="form-floating">
            <select
              class="form-select"
              id="usState"
              aria-label="us state"
              formControlName="usState"
              [ngClass]="{
                'is-invalid': formUtils.hasError(addressForm, 'usState')
              }"
            >
              @for (state of usStates; track state.name) {
                <option [value]="state.abbreviation">
                  {{ state.abbreviation }}
                </option>
              }
            </select>
            <label for="usState">state</label>
            @if (formUtils.hasError(addressForm, 'usState', 'required')) {
              <span class="invalid-feedback"> required </span>
            }
            @if (formUtils.hasError(addressForm, 'usState', 'server')) {
              <span class="invalid-feedback">
                {{ addressForm.controls.city.errors?.['server'] }}
              </span>
            }
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <div class="form-floating">
            <input
              type="text"
              autocomplete="postal-code"
              class="form-control"
              id="postalCode"
              placeholder="postal code"
              aria-label="postal code"
              maxlength="24"
              formControlName="postalCode"
              [ngClass]="{
                'is-invalid': formUtils.hasError(addressForm, 'postalCode')
              }"
            />
            <label for="postalCode">postal code</label>
            @if (formUtils.hasError(addressForm, 'postalCode', 'required')) {
              <span class="invalid-feedback"> required </span>
            }
            @if (formUtils.hasError(addressForm, 'postalCode', 'server')) {
              <span class="invalid-feedback">
                {{ addressForm.controls.city.errors?.['server'] }}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-12">
        <button type="submit" class="btn btn-primary w-100">
          {{ saveText }}
        </button>
      </div>
    </div>
  </div>
</form>
