import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { environment } from '../../src/environments/environment';

interface RecaptchaSettings {
  key: string;
  url: string;
  recaptchaScriptId: string;
}

@Injectable()
export class CaptchaService {
  recaptchaSettings: RecaptchaSettings;

  constructor() {
    this.recaptchaSettings = environment.captcha as RecaptchaSettings;
  }

  setupCaptchaScript(scriptSrc?: string): void {
    if (
      document.querySelector(`#${this.recaptchaSettings.recaptchaScriptId}`)
    ) {
      this.toggleCaptchaBadge();
      return;
    }

    if (!scriptSrc) {
      scriptSrc = this.recaptchaSettings.url;
    }

    const recaptchaScript = document.createElement('script');
    recaptchaScript.id = this.recaptchaSettings.recaptchaScriptId;
    recaptchaScript.src = scriptSrc;

    document.body.append(recaptchaScript);
  }

  toggleCaptchaBadge(hide = false): void {
    const captchaBadge =
      document.querySelector<HTMLElement>('.grecaptcha-badge');
    if (captchaBadge) {
      captchaBadge.style.visibility = hide ? 'hidden' : 'visible';
    }
  }

  getCaptchaToken(siteKey?: string): Observable<string> {
    return defer(
      () =>
        new Promise<string>((resolve, reject) => {
          this.recaptcha.ready(() => {
            this.recaptcha
              .execute(siteKey || this.recaptchaSettings.key, {
                action: 'submit',
              })
              .then((token: string) => resolve(token))
              .catch((err: any) => {
                console.log('captcha error', err);
                reject(err);
              });
          });
        })
    );
  }

  private get recaptcha(): any {
    if ('grecaptcha' in window) {
      return window['grecaptcha'];
    }
  }
}

export class CaptchaServiceTestStub {
  setupCaptchaScript(scriptSrc?: string): void {
    console.log('setupCaptchaScript', scriptSrc);
  }

  toggleCaptchaBadge(hide = false): void {
    console.log('toggleCaptchaBadge', hide);
  }

  getCaptchaToken(siteKey?: string): Observable<string> {
    return of(siteKey || 'siteKey');
  }
}
