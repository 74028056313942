import {
  AfterContentInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { sortBy } from 'lodash-es';
import * as Countries from './countries.json';

interface Country {
  name: string;
  countryCodes: string[];
  isoCodes: string[];
}

interface CountryCode {
  country: string;
  code: string;
}

const numberPattern = /^\d{10,15}$/;

@Component({
  selector: 'app-phone-number',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.sass'],
})
export class PhoneNumberComponent
  implements OnInit, AfterContentInit, OnChanges
{
  @Input() countryCodeControl!: FormControl;
  @Input() phoneControl!: FormControl;
  @Input() serverError = 'server';
  @Input() selectedCountryCode?: string;
  @Input() countryCode?: string;
  @Input() phone?: string;

  countryCodes: CountryCode[] = [];

  ngOnInit(): void {
    this.setupCountries();
    this.setDefaultCountryCode();
  }

  ngAfterContentInit(): void {
    this.trySetCountryCode(this.countryCode);
    this.trySetPhoneNumber(this.phone);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.trySetCountryCode(changes?.['countryCode']?.currentValue);
    this.trySetPhoneNumber(changes?.['phone']?.currentValue);
  }

  trySetCountryCode(countryCode: string | undefined): void {
    const selectedCountryCode = this.countryCodes?.find(
      (cc) => cc.code === `+${countryCode}`
    );

    if (selectedCountryCode) {
      this.countryCodeControl.patchValue(selectedCountryCode?.code);
    }
  }

  trySetPhoneNumber(phone: string | undefined): void {
    // if (!phone) {
    //   return;
    // }
    //
    // if (numberPattern.test(phone)) {
    //   this.phoneControl.patchValue(phone);
    // }

    this.phoneControl.patchValue(phone);
  }

  setupCountries(): void {
    const countries = Countries as Country[];

    sortBy(countries, ['countryCodes']).forEach((c: Country) =>
      c.countryCodes?.forEach((cc) => {
        this.countryCodes.push({
          country: c.isoCodes[1],
          code: `+${cc}`,
        });
      })
    );
  }

  setDefaultCountryCode(): void {
    this.countryCodeControl.patchValue(
      this.selectedCountryCode ?? this.countryCodes[1].code
    );
  }
}

export function getCountryCodeControl(): FormControl {
  return new FormControl(null, [Validators.required]);
}

export function getPhoneControl(): FormControl {
  return new FormControl(null, [
    Validators.required,
    Validators.pattern(numberPattern),
  ]);
}
