import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { FormUtils } from '../utils';
import { PhoneNumberComponent } from '../phone-number/phone-number.component';
import UsStates from './states.json';

interface UsState {
  name: string;
  abbreviation: string;
}

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  postalCode: string;
  state: string;
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, PhoneNumberComponent, NgClass],
  templateUrl: './address.component.html',
  styleUrl: './address.component.sass',
})
export class AddressComponent {
  @Input()
  address?: Address;

  @Input()
  saveText = 'save';

  @Output()
  save = new EventEmitter<Address>();

  readonly usStates: UsState[] = UsStates as UsState[];

  readonly formUtils = FormUtils;

  readonly addressForm = this.fb.nonNullable.group({
    address1: ['', [Validators.required, Validators.maxLength(128)]],
    address2: ['', [Validators.maxLength(128)]],
    city: ['', [Validators.required, Validators.maxLength(64)]],
    usState: ['', [Validators.required, Validators.maxLength(64)]],
    postalCode: ['', [Validators.required, Validators.maxLength(24)]],
  });

  constructor(private fb: FormBuilder) {}

  onSave(): void {
    if (this.addressForm.invalid) {
      return this.addressForm.markAllAsTouched();
    }

    this.save.emit({
      address1: this.addressForm.controls.address1.value,
      address2: this.addressForm.controls.address2.value,
      city: this.addressForm.controls.city.value,
      postalCode: this.addressForm.controls.postalCode.value,
      state: this.addressForm.controls.usState.value,
    });
  }
}
